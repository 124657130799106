import React, { useEffect } from "react";
import LinkIcon from "../components/LinkIcon";
import Modal from "../components/Modal";
import { useState } from "react";
import dghri from "../assets/dghri1.png";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import defaultProfile from "../assets/default.jpg";
import defaultBg from "../assets/default-bg.png";
import CircularLoading from "../components/CircularLoading";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import ModalContent from "../components/ModalContent";
import HighlightIcon from "../components/HighlightIcon";
import { Helmet } from "react-helmet";
import Heading from "../components/Heading";
import VideoLink from "../components/VideoLink";
import ExtendableText from "../components/ExtendableText";
import FormField from "../components/FormField";
import ImageField from "../components/ImageField";
import TextField from "../components/TextField";
import axios from "axios";
import Gallery from "../components/Gallery";
interface Link {
  category_link: {
    id: number;
    link: string | null;
  };
  category_id: number;
  created_at: string;
  icon: string;
  id: number;
  index: number;
  link: string;
  profile_id: number;
  status: number;
  title: string;
  updated_at: string;
  user_id: number;
  description: string;
  highlight: boolean;
  type: string;
  user_name: string;
  gallery_images: {
    link_id: number;
    url: string;
    index: number;
    status: number;
    id: number;
  }[];
}
const Home = () => {
  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fromTemplate, setFromTemplate] = useState<boolean>(false);
  const [cover, setCover] = useState<string>("");
  const [profile, setProfile] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [job, setJob] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [links, setLinks] = useState<Link[]>([]);
  const [bio, setBio] = useState<string>("");
  const [profileId, setProfileId] = useState<number>(0);
  const [linksError, setLinksError] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<number | null>(null);
  const [contactForm, setContactForm] = useState<boolean>(false);
  const [contactFormTitle, setContactFormTitle] = useState<string | null>(null);
  const [isIos, setIsIOS] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<string>("");
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [fields, setFields] = useState([]);

  const updateDonwloads = async () => {
    try {
      const res = await axios.put(
        "https://dev-app.dghri.com/api/contacts_downloaded",
        {
          user_id: name,
        }
      );
    } catch (err) {}
  };
  const { name } = useParams<{ name: string }>();
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);
  async function imageToBase64(imageUrl: string) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const base64Image = await convertBlobToBase64(blob);
    return base64Image;
  }

  // Helper function to convert Blob to Base64
  function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = (reader.result as string).split(",")[1];
        resolve(base64Image);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const saveVcard = async () => {
    const base64Image = await imageToBase64(profile);
    // Define the VCF file template
    let vcfTemplate = `BEGIN:VCARD
VERSION:3.0
PHOTO;ENCODING=b;TYPE=JPG:${base64Image || ""}
N:${username || ""}
TITLE:${job || ""}
ORG:${company || ""}\n`;

    // Add website links to the VCF file template
    for (let i = 0; i < links.length; i++) {
      if (links[i].user_name === "Phone Number") {
        vcfTemplate += `TEL:${links[i].link.replace("tel:", "")}\n`;
      } else if (links[i].user_name === "Message") {
        vcfTemplate += `TEL:${links[i].link.replace("sms:", "")}\n`;
      } else {
        if (links[i].type !== "link") continue;
        if (isIos) {
          vcfTemplate += `X-SOCIALPROFILE;type=${links[i].title}:${links[i].link}\n`;
        } else {
          vcfTemplate += `URL:${links[i].link}\n`;
        }
      }
    }
    const phoneNumbers = links.filter(
      (link) =>
        link.user_name === "Phone Number" || link.user_name === "Message"
    );
    if (phoneNumbers.length === 0) {
      let phoneNumber = "";
      const whatappLink = links
        .find((link) => link.user_name === "Number")
        ?.link.replace("https://wa.me/", "");
      const smsLink =
        links
          .find((link) => link.user_name === "Message")
          ?.link.replace("sms:", "") || "";
      phoneNumber = whatappLink || smsLink || "";
      if (phoneNumber) {
        vcfTemplate += `TEL:${phoneNumber}\n`;
      }
    }

    vcfTemplate += `END:VCARD`;
    // Save the VCF file to the user's computer
    const blob = new Blob([vcfTemplate], {
      type: "text/x-vcard;charset=utf-8",
    });
    await saveAs(blob, `${username}.vcf`);
    await updateDonwloads();
  };
  useEffect(() => {
    const fetchUserInformation = async () => {
      try {
        const res = await axios(
          `${process.env.REACT_APP_BACKEND_SERVER}/api/user/profile/${name}`,
          {
            params: {
              src: searchParams.get("src") || "link_share",
            },
          }
        );

        const { data } = res;
        console.log({
          data,
        });
        setFromTemplate(data.profile.from_template);
        setLinksError(data.links.length === 0);
        setCover(data.profile.cover);
        setProfile(data.profile.image);
        setUsername(data.profile.name);
        setJob(data.profile.jop_title);
        setCompany(data.profile.company_name);
        setUserId(data.profile.user_id);
        setContactForm(data.profile.form_mode ? true : false);
        setContactFormTitle(data.profile.form_title);
        setUserId(data.profile.user_id);

        setLinks(data.links);
        setProfileId(data.profile.id);
        setBio(data.profile.bio);
        setLogo(data.profile.logo);
        setLoading(false);
        const leadFields = data.profile.lead_fields.map((field: any) => {
          return {
            id: field.id,
            profile_id: field.profile_id,
            title: field.title,
            is_required: field.is_required,
            value: "",
            error: false,
          };
        });
        setFields(leadFields);
        // const data = res.data.data;
        setDisclaimer(data.profile.disclaimer);
        if (data.profile.lead_capture) {
          setShowModal(true);
          // Navigate('/lead')
        } else {
          setShowModal(false);
        }
        if (data.profile.status === 0) {
          Navigate("/lock");
        }
        if (data.profile.direct_mode === 1) {
          for (let i = 0; i < data.links.length; i++) {
            if (data.links[i].status === 1 && data.links[i].type === "link") {
              const targetedLink = data.links[i].category_link.link
                ? data.links[i].category_link.link + data.links[i].link
                : data.links[i].link;
              return (window.location.href = targetedLink);
            }
          }
        }
      } catch (err) {
        setLoading(false);
        Navigate("/error");
      }
    };
    fetchUserInformation();
  }, [name, Navigate]);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);
  useEffect(() => {
    // Function to get the user's location
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Retrieve latitude and longitude from the position object
            const { latitude, longitude } = position.coords;

            // Save the location in the component's state
            setLocation({ latitude, longitude });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };

    // Call the function to get the location
    getLocation();
  }, []); // Empty dependency array to run the effect only once

  const header = username ? `${username || ""} | ${job || "Dghri"}` : "Dghri";
  return (
    <>
      <Helmet>
        <title property="og:title">{header}</title>
        <meta name="description" content={`${username} | Dghri`} />
        <link rel="apple-touch-icon" sizes="180x180" href={profile} />
        <meta property="og:image" content={profile} />
        <meta property="og:description" content="My Digital Business Card" />
        <link rel="icon" href={profile || dghri} />
      </Helmet>
      {loading && <CircularLoading panner="" noPanner={true} button={false} />}
      {showModal && !loading && (
        <div className="">
          <Modal
            onClose={() => {
              setShowModal(false);
            }}
            content={
              <ModalContent
                profileImg={profile ? profile : defaultProfile}
                username={username}
                profile_id={profileId}
                user_id={userId}
                location={location}
                disclaimer={disclaimer}
                fields={fields || []}
                onClose={() => {
                  setShowModal(false);
                }}
              />
            }
          />
        </div>
      )}
      {!loading && (
        <div className="max-w-md  md:border-2 md:shadow-xl h-[90vh]  md:h-screen overflow-y-scroll overflow-x-hidden flex flex-col justify-center items-center mx-auto font-trial">
          {/* cover photo */}
          <div className="h-[14rem] w-full">
            <img
              src={cover || defaultBg}
              className="h-full w-full aspect-[16/9] object-cover "
              alt=""
            />
          </div>
          <div className="relative w-full h-[75%] flex flex-col justify-start items-center">
            {/* profile photo */}
            <div className=" p-[0.4rem] bg-white absolute top-[-5rem] z-10 rounded-full ">
              <img
                className="rounded-full h-[10rem] w-[10rem] object-cover drop-shadow-md"
                src={profile || defaultProfile}
                loading="lazy"
                alt=""
              />
              <div className=" bg-white absolute top-[7.7rem] left-[7rem] z-10 rounded-full ">
                <img
                  className="rounded-full h-[3rem] w-[3rem] object-cover"
                  src={logo || dghri}
                  loading="lazy"
                  alt=""
                />
              </div>
            </div>
            {/* contact info */}
            <div className="mt-[6rem] flex flex-col justify-center items-center ">
              <h1 className="font-semibold text-2xl  ">{username}</h1>
              <h3 className="text-lg font-semibold text-[#414141] w-full text-center px-4">
                {job} {company ? `at ${company}` : ""}
              </h3>
              <h3 className="text-sm  ">{bio}</h3>
            </div>
            <button
              onClick={saveVcard}
              className="bg-black rounded-full py-4 px-20 mb-6 outline-none mt-3 text-white flex justify-center items-center font-semibold drop-shadow-xl"
            >
              Save Contact
            </button>
            {/* active links */}
            <div className="flex flex-wrap justify-center items-center gap-x-4 mb-6 w-full gap-y-3 ">
              {links.map((link) => {
                if (link.type === "video") {
                  return <VideoLink key={link.id} target={link.link} />;
                } else if (link.type === "heading") {
                  return <Heading key={link.id} title={link.link} />;
                } else if (link.type === "extendable") {
                  return (
                    <ExtendableText
                      key={link.id}
                      title={link.title}
                      description={link.description}
                    />
                  );
                } else if (link.type === "image") {
                  return <ImageField key={link.id} target={link.link} />;
                } else if (link.type === "text") {
                  return <TextField key={link.id} description={link.link} />;
                } else {
                  if (link.highlight) {
                    return (
                      <HighlightIcon
                        name={link.title}
                        target={
                          link.category_link.link
                            ? link.category_link.link + link.link
                            : link.link
                        }
                        icon={link.icon}
                        description={link.description}
                        key={link.id}
                        user_id={userId}
                        id={link.id}
                        profile_id={profileId}
                        from_template={fromTemplate}
                      />
                    );
                  } else if (link.type === "gallery") {
                    return (
                      <Gallery
                        key={link.id}
                        gallery_images={link.gallery_images}
                      />
                    );
                  } else {
                    return (
                      <LinkIcon
                        profile_id={profileId}
                        name={link.title}
                        target={
                          link.category_link.link
                            ? link.category_link.link + link.link
                            : link.link
                        }
                        icon={link.icon}
                        key={link.id}
                        id={link.id}
                        user_id={userId}
                        from_template={fromTemplate}
                      />
                    );
                  }
                }
              })}

              {linksError && (
                <h3 className="text-lg font-semibold text-[#414141] w-full text-center px-4">
                  This profile does not have any active links yet
                </h3>
              )}
            </div>

            {contactForm && (
              <FormField
                title={contactFormTitle || ""}
                profile_id={profileId}
              />
            )}

            <div className="flex justify-start items-center">
              <a
                href="https://dghri.com/"
                target="_blank"
                className="bg-white box-shadow font-semibold border-[2px] col-span-3  mb-8  border-white py-3 px-9 rounded-full"
              >
                Create your own profile
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
