import React from "react";
import YouTube from "react-youtube";
const VideoLink: React.FC<{ target: string }> = ({ target }) => {
  return (
    <div className="w-full h-[14rem] px-4">
      <iframe
        className="w-full h-full rounded-xl drop-shadow-lg"
        src={`https://www.youtube.com/embed/${target}`}
        title="Youtube link video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};

export default VideoLink;
