import classes from "./Modal.module.css";
import React from "react";
import ReactDOM from "react-dom";

const Backdrop: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return <div className={classes.backdrop} onClick={onClose}></div>;
};
const ModalOverlay: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className={`${classes.modal}`}>
      <div className={` w-full h-full`}>{children}</div>
    </div>
  );
};

const portalElement: Element | null = document.getElementById("overlays");

const Modal: React.FC<{
  onClose: () => void;

  content: React.ReactNode;
}> = ({ onClose, content }) => {
  if (!portalElement) return null;

  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>{content}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};
export default Modal;
