import Autoplay from "embla-carousel-autoplay";

import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import { type CarouselApi } from "./ui/carousel";

const Gallery: React.FC<{
  gallery_images: {
    link_id: number;
    url: string;
    index: number;
    status: number;
    id: number;
  }[];
}> = ({ gallery_images }) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!api) return;
    const onSelect = () => {
      setCurrent(api.selectedScrollSnap());
    };
    api.on("select", onSelect);
    setCurrent(api.selectedScrollSnap());
    return () => {
      api.off("select", onSelect);
    };
  }, [api]);

  const goToSlide = (index: number) => {
    api?.scrollTo(index);
  };
  return (
    <Carousel
      plugins={[
        Autoplay({
          delay: 4000,
          stopOnInteraction: false,
          stopOnMouseEnter: true,
        }),
      ]}
      className="w-full relative max-h-[16rem] px-4 rounded-[8px]"
      setApi={setApi}
    >
      <CarouselContent className="">
        {gallery_images.map(
          (image, index) =>
            image.status === 1 && (
              <CarouselItem
                className="w-full h-[14rem] rounded-[8px]  object- aspect-video"
                key={index}
              >
                <img
                  src={image.url}
                  alt=""
                  className="h-full w-full rounded-[8px] object-cover"
                />
              </CarouselItem>
            )
        )}
      </CarouselContent>
      <div className="absolute bottom-1 right-0 w-full justify-center items-center flex gap-x-1">
        {gallery_images.map((image, index) => (
          <button
            onClick={() => goToSlide(index)}
            key={index}
            className={`w-6 h-1 ${
              current === index ? "bg-white" : "bg-[#b6b6b6]"
            }   rounded-full transition-all`}
          ></button>
        ))}
      </div>
    </Carousel>
  );
};

export default Gallery;
