import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import clsases from "./CircularLoading.module.css";
const CircularLoading: React.FC<{ panner: string; button: boolean; noPanner:boolean }> = ({
  panner,
  button,
  noPanner
}) => {
  return (
    <div className={`${clsases.circle} ${!button && 'h-[100vh]'}`}>
      <CircularProgress
        color="inherit"
        size={`${button ? "22px" : "64px"}`}
        className={`${button ? "" : "mb-8"}`}
      />
      {!noPanner && <p>{panner}...</p>}
    </div>
  );
};
export default CircularLoading;
