import React from "react";
import { useState } from "react";
import emailImg from "../assets/email.png";
import nameImg from "../assets/name.png";
import messageImg from "../assets/message.png";
import phoneImg from "../assets/phone.png";
import CircularLoading from "./CircularLoading";
import axios from "axios";

const FormField: React.FC<{
  title: string;
  profile_id: number | undefined;
}> = ({ title, profile_id }) => {
  const [emailError, setEmailError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [FormError, setFormError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setNameError("");
    setPhoneError("");
    setEmailError("");

    let isValid = true;

    // Name validation
    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    }

    if (/^[0-9]+$/.test(phone) === false || phone.trim().length < 10) {
      setPhoneError("Phone is required");
      isValid = false;
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setEmailError("Invalid email address");
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      try {
        let data = {
          name,
          email,
          phone,
          message,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_SERVER}/api/support_form`,
          {
            profile_id,
            name,
            email,
            phone,
            message,
          }
        );
        setSuccess(true);
        setEmail("");
        setName("");
        setPhone("");
        setMessage("");
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        // const res = await fetch(
        //   "https://lib.dghri.com/sendNotification.php",
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(data),
        //   }
        // );
        setLoading(false);
      } catch (err: Error | any) {
        setFormError(err?.message);
      }
    }
  };

  return (
    <form className=" flex flex-col px-6 font-trial w-full  mb-6">
      <div className="flex flex-col justify-center px-5 py-5 items-center font-trial bg-gray-100 rounded-2xl">
        <h3 className="text-lg font-semibold capitalize w-full text-center px-4">
          {title}
        </h3>
        <div
          className={`${
            emailError ? "border-red-600" : ""
          } w-full border-2 flex items-center rounded-lg px-3 mb-2 mt-4`}
        >
          <img
            src={emailImg}
            className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
            alt=""
          />
          <input
            type="Email"
            placeholder="Email"
            className={`${
              emailError ? "border-red-600" : ""
            }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            onFocus={() => {
              setEmailError("");
            }}
          />
        </div>
        <div
          className={`${
            nameError ? "border-red-600" : ""
          } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
        >
          <img
            src={nameImg}
            className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
            alt=""
          />
          <input
            type="text"
            placeholder="Name"
            className={`${
              nameError ? "border-red-600" : ""
            }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            onFocus={() => {
              setNameError("");
            }}
          />
        </div>
        <div
          className={`${
            phoneError ? "border-red-600" : ""
          } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
        >
          <img
            src={phoneImg}
            className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
            alt=""
          />
          <input
            type="text"
            placeholder="Phone"
            className={`${
              phoneError ? "border-red-600" : ""
            }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneError("");
            }}
            onFocus={() => {
              setPhoneError("");
            }}
          />
        </div>
        <div
          className={`= w-full border-2 flex items-center rounded-lg px-3 mb-2`}
        >
          <img
            src={messageImg}
            className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
            alt=""
          />
          <input
            type="text"
            placeholder="Message"
            className={` border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>
        <button
          disabled={loading}
          onClick={submitHandler}
          className={`${
            loading ? "bg-[#222]" : "bg-black"
          } rounded-full h-[2.8rem] w-[9.5rem] mt-2  outline-none  text-white flex justify-center items-center font-semibold drop-shadow-xl ${
            success ? "bg-green-600 text-white" : ""
          } transition-all`}
        >
          {loading ? (
            <CircularLoading button={true} noPanner={true} panner="" />
          ) : (
            <span>{success ? "Connectd" : "Submit"}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default FormField;
