import React from 'react'
import lockImg from '../assets/lock.png'
const ProfileLock = () => {
  return (
    <div className="h-[90vh] overflow-hidden w-full flex justify-center items-center">
      <div className="rounded-xl flex flex-col gap-6 py-20 justify-center items-center w-[20rem]">
        <img src={lockImg} alt="" />
        <h3 className="text-2xl font-semibold abel">
          Sorry this profile is locked
        </h3>
      </div>
    </div>
  );
}

export default ProfileLock